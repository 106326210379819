<template>
    <!-- Start Google MAp -->
    <div class="google-map-style-1">
        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14554259.179133086!2d-105.54385245388013!3d37.49334218664659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sbd!4v1630777307491!5m2!1sen!2sbd" width="600" height="550" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
        <iframe src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Intellectworks Digital Solutions&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="600" height="550" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
    <!-- End Google MAp -->
</template>

<script>
    export default {
        name: 'GoogleMap'
    }
</script>
<template>
    <Layout header-class="header-transparent" :show-newsletter="false" :show-cta="false">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-default height-850 bg_image"
             data-black-overlay="7"
             :style="{'background-image': `url(https://firebasestorage.googleapis.com/v0/b/intellectworks-2ee0c.appspot.com/o/BANNER.jpg?alt=media&token=a553651b-9c93-4f84-8596-88813b7fa7c5)`}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner text-center">
                            <h1 class="title display-one" data-aos="fade-up" data-aos-delay="100">
                               Software Development <br/>
                                <span class="theme-gradient">IT Services</span>
                                &
                                <span class="theme-gradient">Consulting</span>.
                            </h1>
                            <p class="description" data-aos="fade-up" data-aos-delay="150">
                                Scalable, Agile, And Cost-Effective Software Product Development and Digital Marketing Services
                            </p>
                            <div class="button-group" data-aos="fade-up" data-aos-delay="200">
                                <router-link class="btn-default btn-medium btn-icon" to="#">
                                    Get Quote
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                                </router-link>
                                <router-link class="btn-default btn-medium btn-border btn-icon" to="/contact">
                                    Contact Us
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Elements Area -->
        <div class="rwt-tab-area rn-section-gap">
            <div class="container">
                <div class="row mb--40">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Business Strategy"
                            title="We are here to solve your problem and deliver your needs"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <Tab :tab-data="tabData" :tab-style="3"/>
            </div>
        </div>
        <!-- End Elements Area -->


        <!-- Start Elements Area -->
        <div class="rwt-split-area no-radius">
            <div class="wrapper">
                <Split :split-data="splitOneData"/>
            </div>
        </div>
        <!-- End Elements Area -->

        <!-- Start Elements Area -->
        <div class="rwt-split-area">
            <div class="wrapper">
                <Split :split-data="splitTwoData" :split-style="4"/>
            </div>
        </div>
        <!-- End Elements Area -->

        <!-- <div class="main-content">
            <div class="rwt-elements-area rn-section-gap">
                <div class="container-fluid plr--30">
                    <div class="row">
                        <div class="col-lg-12">
                            <SectionTitle
                                text-align="center"
                                subtitle="Portfolio "
                                title="Amazing clients have allowed us to produce work <br /> we are proud of!"
                                data-aos="fade-up"
                            />
                        </div>
                    </div>
                    <Portfolio :portfolio-data="portfolioData" column="col-lg-4 col-md-6 mt--30 box-grid-layout no-overlay"/> 
                </div>
            </div>
        </div> -->

        <Separator/>

        <!-- Start Pricing Area -->
        <!-- <div class="rwt-pricingtable-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Our Pricing Table"
                            title="Business Pricing Plan."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row mt--40">
                    <div class="col-lg-8 offset-lg-2">
                        <AdvancePricing/>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Pricing Area -->

        <Separator/>

        <!-- <div class="blog-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Latest News"
                            title="Latest News From The Digital World."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 mt--30"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="100 + index"
                         v-for="(blog, index) in posts"
                         :key="index"
                         v-if="index < 3">
                        <BlogPost :blog="blog"/>
                    </div>
                </div>
            </div>
        </div> -->
    </Layout>
</template>

<script>
    import Layout from '../../components/common/Layout'
    import Icon from '../../components/icon/Icon'
    import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
    import Separator from '../../components/elements/separator/Separator'
    import AdvancePricing from '../../components/elements/pricing/AdvancePricing'
    import BlogPost from '../../components/blog/BlogPost'
    import BlogPostMixin from '../../mixins/BlogPostMixin'
    import Portfolio from '../../components/elements/portfolio/Portfolio'
    import Split from '../../components/elements/split/Split'
    import Tab from '../../components/elements/tab/Tab'

    export default {
        name: 'InternationalConsulting',
        components: {Tab, Split, Portfolio, BlogPost, AdvancePricing, Separator, SectionTitle, Icon, Layout},
        mixins: [BlogPostMixin],
        data() {
            return {
                splitOneData: {
                    image: 'split-01.jpg',
                    title: 'Who We Are?',
                    description: `We aspire to be a team that can collaborate with you to turn your business ideas into a successful product/solution. We are a reputable  product engineering firm that began operations in 2018 and has helped our clients lead in the business domain ever since. We provide comprehensive solutions in Retail, Digital Advertising, Hospitality and Travel to help you stand out.`,
                    list: [
                        'We Value - Honesty and integrity, which generates long term client loyality',
                        'A sincere focus on providing exceptional customer service',
                        'Key Strenght- Qulity-QMS system, which aims to continually review and improve our performance',
                        'Reputation-Widely respected for delivering first class service'
                    ],
                    btn: {
                        text: 'Contact With Us',
                        link: '/contact'
                    }
                },
                splitTwoData: {
                    image: 'split-02.jpg',
                    title: 'Our Story In Numbers.',
                    description: `Our amazing clients and team members make us who we are.`,
                    counterData: [
                        {
                            number: 65,
                            title: 'Happy Clients',
                        },
                        {
                            number: 90,
                            title: 'Projects Delivered',
                        },
                        {
                            number: 12,
                            title: 'Employees',
                        },
                        {
                            number: 7,
                            title: 'Countries Serving',
                        }
                    ]
                },
                portfolioData: [
                    {
                        id: 1,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        title: 'App Development',
                        subtitle: 'Branded client',
                        date: '30 May 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 2,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        title: 'Business Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 3,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        title: 'Photoshop Design',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'art',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 4,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        title: 'Native Application',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-04.jpg`)
                        }
                    },
                    {
                        id: 5,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        title: 'Vue.js Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'Test',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 6,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        title: 'App Installment',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'Test',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-06.jpg`)
                        }
                    }
                ],
                tabData: [
                    {
                        id: 'strategy-menu',
                        text: 'Software Development',
                        dataList: [
                            {
                                image: 'service-01.jpg',
                                title: 'Custom Application Development',
                                description: 'We are consulting firm that provides world-class solutions and Custom product engineering services to start-ups and businesses'
                            },
                            {
                                image: 'service-02.jpg',
                                title: 'Product Engineering',
                                description: 'We employ lean startup approach and Scrum framework to build great digital products that add value to businesses and users.'
                            }
                        ],
                    },
                    {
                        id: 'development-menu',
                        text: 'App Development',
                        dataList: [
                            {
                                image: 'service-03.jpg',
                                title: 'Android & iOS App Development',
                                description: 'Build a secure, future-proof application through the latest technologies and enhance your user experience.'
                            },
                            {
                                image: 'service-04.jpg',
                                title: 'Technology Consulting',
                                description: 'Explore new opportunities, get insights and overcome your barriers by consulting with our experts.'
                            }
                            
                        ],
                    },
                    {
                        id: 'reporting-menu',
                        text: 'Digital Marketing',
                        dataList: [
                            {
                                image: 'seo.jpg',
                                title: 'Search Engine Optimization',
                                description: 'You need to get your brand discovered and make them list down on top of search results.SEO is the key to achieve in top search results.'
                            },
                            {
                                image: 'socialmedia.jpg',
                                title: 'Social Media Marketing',
                                description: 'Social media is an excellent platform for businesses to build relationships with your customers and also meet new potential customers on the go.'
                            },
                            {
                                image: 'service-06.jpg',
                                title: 'Our Digital Platforms',
                                description: 'Facebook, Instagram, Youtube, Twitter, Linkedin.This eventually helps us to emotionally connect with the potential customers.'
                            }
                        ]
                    }
                ]
            }
        }
    }
</script>